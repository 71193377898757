import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Loading from "../pageFragments/Loading";
import { useAuth } from "../../context/useAuth";

//Styled Components Import
import LoginContentWrapper from "../pageFragments/LoginContentWrapper";
import LabelledTextInput from "../pageFragments/LabelledTextInput";
import ConfirmModal from "../pageFragments/ConfirmModal";

const Errors = {
  "auth/invalid-email": "Invalid Email Format",
  "auth/user-not-found": "User Not Found",
  "auth/wrong-password": "Incorrect Password",
  "auth/internal-error": "Must fill out both fields",
  "auth/user-disabled": "The user has been disabled"
};

const AdminLogin = () => {
  const { login, user, sendPasswordReset } = useAuth();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [showConfirmPasswordReset, setShowConfirmPasswordReset] =
    useState(false);
  const [loading, setLoading] = useState(false);

  const navHistory = useHistory();

  useEffect(() => {
    if (user !== null) {
      //CHECK IF USER IS ADIDAS REP AND IF SO GO TO THE REP DASHBOARD
      navHistory.push("/admin");
    }
  }, [user, navHistory]);

  const onTextInputChange = (e) => {
    if (e.target.id === "email") {
      setEmail(e.target.value);
    } else if (e.target.id === "password") {
      setPassword(e.target.value);
    }
  };

  const submitLoginCredentials = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      //User is returned on login but don't need it here
      await login(email, password);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const onConfirmPasswordReset = async () => {
    setShowConfirmPasswordReset(false);
    try {
      await sendPasswordReset(email);
      //TODO: Show User confirmation that email was sent
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container>
      <LoginContentWrapper title='ADMIN LOGIN'>
        <form onSubmit={submitLoginCredentials}>
          <InputsWrapper>
            <EmailPasswordInput
              marginBottom='10px'
              type='text'
              labelText='EMAIL ADDRESS'
              id='email'
              onChange={onTextInputChange}
              value={email}
            />
            <EmailPasswordInput
              marginBottom='15px'
              type='password'
              labelText='PASSWORD'
              id='password'
              onChange={onTextInputChange}
              value={password}
            />
            {error !== null ? (
              <ErrorMessage>{Errors[error.code]}</ErrorMessage>
            ) : null}
            <Button type='submit' primary>
              GO
            </Button>
          </InputsWrapper>
        </form>
      </LoginContentWrapper>
      {/* <PasswordReset
        onClick={email !== "" ? setShowConfirmPasswordReset : null}
      >
        Password Reset
      </PasswordReset> */}
      <ConfirmModal
        show={showConfirmPasswordReset}
        onClose={() => setShowConfirmPasswordReset(false)}
        onConfirm={onConfirmPasswordReset}
        closeButtonHidden={true}
        disableCloseOnOutsideClick={true}
        title='CONFIRM PASSWORD RESET'
        message={`This will send an email to ${email} for a password reset.`}
        buttonTitle='Send'
      />
      <Loading isLoading={loading} />
    </Container>
  );
};

const Button = styled.button`
  border: none;
  border-radius: 8px;
  padding: 3px 10px;
  margin: 2px;
  font-family: "Montserrat", sans-serif;
  font-size: 1.1em;
  color: white;
  background-color: black;
  cursor: pointer;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const EmailPasswordInput = styled(LabelledTextInput)`
  min-width: 300px;
`;

const ErrorMessage = styled.div`
  padding-bottom: 10px;
  color: red;
`;

export default AdminLogin;
