import React from "react";
import Nav from "./Nav";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const Header = ({ links, admin }) => {
  return (
    <StyledHeader>
      <InnerHeader>
        <NavLink exact to='/'>
          <LogoContainer>
            <img src='bha_logo.svg' alt='BhA Logo' />
          </LogoContainer>
        </NavLink>
        <Nav links={links} admin={admin} />
      </InnerHeader>
    </StyledHeader>
  );
};

const StyledHeader = styled.header`
  background-color: var(--dark-green);
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: center;
  position: fixed;
  left: 0;
  z-index: 90;
`;

const InnerHeader = styled.div`
  height: 100%;
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  @media only screen and (min-width: 960px) {
    max-width: 960px;
  }
`;

const LogoContainer = styled.div`
  height: 100%;
  padding-left: 10px;
  display: flex;
  align-items: center;

  & > img {
    height: 35px;
  }
`;

export default Header;
