import { createContext, useState, useEffect, useContext } from "react";

import {
  getFirestore,
  collection,
  onSnapshot,
  getDoc,
  getDocs,
  doc
} from "firebase/firestore";

import { getAnalytics } from "firebase/analytics";

//CREATE AUTH CONTEXT
const FirebaseContextClient = createContext();

//AUTH CONTEXT PROVIDER TO WRAP AROUND APP
const FirebaseContextProviderClient = ({ children }) => {
  //INTERNAL
  const db = getFirestore();
  const analytics = getAnalytics();

  //CONTEXT STATE
  const [officeStatus, setOfficeStatus] = useState(null);
  const [suppliers, setSuppliers] = useState([]);
  const [contentAllowedTypes, setContentAllowedTypes] = useState([]);

  //LISTENERS
  useEffect(() => {
    let unsubscribe;
    unsubscribe = onSnapshot(
      doc(db, "office-info", "status-schedule"),
      (doc) => {
        //Deconstruct Doc to translate Timestamps to JS Dates
        const status = {
          currentStatus: {
            isOpen: doc.data().currentStatus.isOpen,
            returnTime: doc.data().currentStatus.returnTime.toDate()
          },
          schedule: []
        };
        doc.data().schedule.map((day) =>
          status.schedule.push({
            isOpen: day.isOpen,
            name: day.name,
            open: day.open.toDate(),
            close: day.close.toDate()
          })
        );
        setOfficeStatus(status);
      }
    );
    return () => {
      unsubscribe();
    };
  }, [db]);

  useEffect(() => {
    getDoc(doc(db, "site-helpers", "supplier-standard-info")).then((doc) => {
      setContentAllowedTypes(doc.data()["allowedContentTypes"]);
    });
  }, [db]);

  useEffect(() => {
    getDocs(collection(db, "suppliers")).then((snapshot) => {
      const suppliers = [];
      snapshot.forEach((doc) => {
        let supplierInfo = doc.data();
        supplierInfo["id"] = doc.id;
        //Deconstruct Doc to translate Timestamps to JS Dates
        Object.keys(supplierInfo.content).map(
          (key) =>
            (supplierInfo.content[key].uploadDate =
              supplierInfo.content[key].uploadDate.toDate())
        );

        suppliers.push(supplierInfo);
      });
      suppliers.sort((a, b) =>
        a.details.name > b.details.name
          ? 1
          : b.details.name > a.details.name
          ? -1
          : 0
      );
      setSuppliers(suppliers);
    });
  }, [db]);

  //RETURN STATEMENT FOR COMPONENT
  return (
    <FirebaseContextClient.Provider
      value={{
        officeStatus,
        suppliers,
        contentAllowedTypes,
        analytics
      }}
    >
      {children}
    </FirebaseContextClient.Provider>
  );
};

export default FirebaseContextProviderClient;

//CREATE A HOOK TO BE USED ON A CONSUMER COMPONENT TO READ AUTH VARIABLES AND METHODS
export const useFirebaseClient = () => useContext(FirebaseContextClient);
