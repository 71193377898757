import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../context/useAuth";

const AdminRoute = ({ children, ...rest }) => {
  const { user } = useAuth();
  return (
    <Route
      {...rest}
      render={() =>
        user !== null ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/adminLogin"
            }}
          />
        )
      }
    />
  );
};

export default AdminRoute;
