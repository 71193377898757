import React, { Fragment } from "react";
import Header from "../Header";
import AdminDropdownMenu from "../AdminDropdownMenu";

const AdminPageLayout = ({ children }) => {
  const links = [
    { name: "OFFICE STATUS", url: "/admin" },
    { name: "MANAGE CONTENT", url: "/manage-content" }
  ];

  return (
    <Fragment>
      <Header links={links} admin={<AdminDropdownMenu />} />
      {children}
    </Fragment>
  );
};

export default AdminPageLayout;
