import inititializeFirebase from "../services/firebase";
import { createContext, useState, useEffect, useContext } from "react";
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut
} from "firebase/auth";

//INITIALIZE FIREBASE
//DONE HERE AS ITS THE TOP LEVEL ENTRY TO FIREBASE IN THE APP
inititializeFirebase();

//CREATE AUTH CONTEXT
const AuthContext = createContext();

//AUTH CONTEXT PROVIDER TO WRAP AROUND APP
const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  //MONITOR USER STATUS
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, []);

  //SIGNIN USER
  const login = async (email, password) => {
    //Returns a User or an Error if no User
    return await signInWithEmailAndPassword(getAuth(), email, password);
  };

  //SIGNOUT USER
  const logout = () => {
    signOut(getAuth());
  };

  //SEND PASSWORD RESET EMAIL
  const sendPasswordReset = async (email) => {
    return await sendPasswordResetEmail(getAuth(), email);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, sendPasswordReset }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;

//CREATE A HOOK TO BE USED ON A CONSUMER COMPONENT TO READ AUTH VARIABLES AND METHODS
export const useAuth = () => useContext(AuthContext);
