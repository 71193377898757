import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { useDropzone } from "react-dropzone";
import Jimp from "jimp/es";
import LabelledTextInput from "../../pageFragments/LabelledTextInput";
import LabelledTextArea from "../../pageFragments/LabelledTextArea";

const SupplierInfoInput = ({ selectedSupplier, onCreateOrUpdateSupplier }) => {
  const [imageInfo, setImageInfo] = useState();
  const [image, setImage] = useState();

  const onDrop = useCallback(async (acceptedFiles) => {
    setImageInfo(acceptedFiles[0]);

    //Read the image from file upload to display
    var reader = new FileReader();
    reader.onload = async function (e) {
      var contents = e.target.result;
      try {
        const image = await Jimp.read(contents);
        image.background(0xffffffff);
        image.contain(250, 250);
        image.quality(30);
        setImageInfo(await image.getBufferAsync(Jimp.MIME_JPEG));
        const resizedImage = await image.getBase64Async(Jimp.MIME_JPEG);
        setImage(resizedImage);
      } catch (error) {
        console.log(error);
      }
    };
    reader.readAsDataURL(acceptedFiles[0]);
  }, []);

  const onSubmit = (supplierDetails) => {
    onCreateOrUpdateSupplier(supplierDetails, imageInfo);
  };

  //HOOKS
  const { register, handleSubmit } = useForm();

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1
  });

  return (
    <Container>
      <Dropzone {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        {selectedSupplier === undefined && imageInfo === undefined ? (
          <p>Drop a 500px x 500px JPG Here</p>
        ) : imageInfo !== undefined ? (
          <img src={image} alt='' />
        ) : (
          <img src={selectedSupplier.details.imageURL} alt='' />
        )}
      </Dropzone>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <LabelledTextInput
          labelText={"Name"}
          placeholder={"Supplier Name"}
          defaultValue={
            selectedSupplier !== undefined
              ? selectedSupplier.details.name
              : undefined
          }
          capitalized={true}
          marginBottom={"10px"}
          {...register("supplierName")}
        />
        <LabelledTextArea
          labelText={"Phone Numbers"}
          marginBottom={"10px"}
          placeholder={`x-xxx-xxx-xxxx\nx-xxx-xxx-xxxx`}
          defaultValue={
            selectedSupplier !== undefined
              ? selectedSupplier.details.phone
              : undefined
          }
          {...register("phoneNumbers")}
        />
        <LabelledTextArea
          labelText={"Email Addresses"}
          marginBottom={"10px"}
          placeholder={`info@website.com\norders@website.com`}
          defaultValue={
            selectedSupplier !== undefined
              ? selectedSupplier.details.email
              : undefined
          }
          {...register("emailAddresses")}
        />
        <LabelledTextInput
          labelText={"Website"}
          marginBottom={"10px"}
          placeholder={"https://website.com"}
          defaultValue={
            selectedSupplier !== undefined
              ? selectedSupplier.details.website
              : undefined
          }
          {...register("website")}
        />
        <LabelledTextArea
          labelText={"Address"}
          marginBottom={"10px"}
          placeholder={`Street Address\nCity, Province, Postal Code`}
          defaultValue={
            selectedSupplier !== undefined
              ? selectedSupplier.details.address
              : undefined
          }
          {...register("address")}
        />
        <LabelledTextArea
          labelText={"Description"}
          marginBottom={"10px"}
          placeholder={`Supplier Description...`}
          defaultValue={
            selectedSupplier !== undefined
              ? selectedSupplier.details.description
              : undefined
          }
          {...register("description")}
        />
        <SubmitButton type='submit' value='SAVE' />
      </Form>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: black;

  @media screen and (min-width: 550px) {
    width: 500px;
  }

  @media screen and (max-width: 550px) {
    width: 100%;
  }
`;

const Dropzone = styled.div`
  border: 1px solid black;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  overflow: none;

  &.dropzone {
    border: 1px solid black;
    background-color: white;
    border-radius: 15px;
    padding: 5px;
    overflow: hidden;
    cursor: pointer;
  }

  & > input {
    width: 100%;
    height: 100%;
  }

  & > p {
    text-align: center;
    color: black;
  }

  & > img {
    width: 100%;
    height: 100%;
  }
`;

const Form = styled.form`
  color: black;
`;

const SubmitButton = styled.input`
  border: none;
  border-radius: 8px;
  padding: 3px 10px;
  margin: 2px;
  font-family: "Montserrat", sans-serif;
  font-size: 1.1em;
  color: white;
  background-color: black;
  cursor: pointer;
`;

export default SupplierInfoInput;
