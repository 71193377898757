import React, { Fragment } from "react";
import styled from "styled-components";

const SupplierInfoDisplay = ({
  selectedSupplier,
  onEditSupplier,
  onDeleteSupplier,
  onViewContent,
  isAdmin = false
}) => {
  return (
    <Wrapper>
      <Logo>
        <img src={selectedSupplier.details.imageURL} alt='' />
      </Logo>
      <Content>
        <NameHeader>
          <Name>{selectedSupplier.details.name}</Name>
          {isAdmin ? (
            <div>
              <EditOrViewButton onClick={onEditSupplier}>Edit</EditOrViewButton>
              <DeleteButton onClick={onDeleteSupplier}>Delete</DeleteButton>
            </div>
          ) : (
            <div>
              <EditOrViewButton onClick={() => onViewContent(selectedSupplier)}>
                View Content
              </EditOrViewButton>
            </div>
          )}
        </NameHeader>

        {selectedSupplier.details.website.length > 0 ? (
          <Fragment>
            <Label>Website</Label>
            <Info>{selectedSupplier.details.website}</Info>
          </Fragment>
        ) : null}
        {selectedSupplier.details.phone.length > 0 ? (
          <Fragment>
            <Label>Phone Numbers</Label>
            <Info>{selectedSupplier.details.phone}</Info>
          </Fragment>
        ) : null}
        {selectedSupplier.details.email.length > 0 ? (
          <Fragment>
            <Label>Email Addresses</Label>
            <Info>{selectedSupplier.details.email}</Info>
          </Fragment>
        ) : null}
        {selectedSupplier.details.address.length > 0 ? (
          <Fragment>
            <Label>Address</Label>
            <Info>{selectedSupplier.details.address}</Info>
          </Fragment>
        ) : null}
        {selectedSupplier.details.description.length > 0 ? (
          <Fragment>
            <Label>Description</Label>
            <Info>{selectedSupplier.details.description}</Info>
          </Fragment>
        ) : null}
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 20px;

  @media screen and (max-width: 550px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Logo = styled.div`
  width: 150px;
  height: 150px;
  flex-shrink: 0;

  & > img {
    width: 100%;
    height: 100%;
  }
`;

const Content = styled.div`
  color: black;
  padding: 20px;
  flex-grow: 1;

  @media screen and (max-width: 550px) {
    width: 100%;
    padding: 10px 0;
  }
`;

const NameHeader = styled.div`
  width: 100%;
  margin-bottom: 10px;
  border-bottom: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Name = styled.p`
  font-weight: bold;
  font-size: 1.4em;
  color: black;
`;

const EditOrViewButton = styled.button`
  border: none;
  border-radius: 8px;
  padding: 3px 10px;
  margin: 2px 5px;
  font-family: "Montserrat", sans-serif;
  font-size: 1.1em;
  color: white;
  background-color: black;
  cursor: pointer;
`;

const DeleteButton = styled.button`
  border: none;
  border-radius: 8px;
  padding: 3px 10px;
  margin: 2px 5px;
  font-family: "Montserrat", sans-serif;
  font-size: 1.1em;
  color: white;
  background-color: red;
  cursor: pointer;
`;

const Label = styled.p`
  color: gray;
  font-weight: bold;
  font-size: 0.7em;
`;

const Info = styled.p`
  color: black;
  font-size: 1.1em;
  padding-left: 10px;
  margin-bottom: 20px;
  white-space: pre-wrap;
`;

export default SupplierInfoDisplay;
