import React, { useState, useEffect, Fragment } from "react";
import Filter from "./Filter";
import ContentFragment from "./ContentFragment";
import { useFirebaseClient } from "../../../context/useFirebaseClient";
import styled from "styled-components";

const categoryTitles = {
  Catalogue: "CATALOGUES",
  Flyer: "FLYERS",
  "Digital Media": "DIGITAL MEDIA"
};

const FilterableContentSection = ({
  displayInCategories = true,
  showFilter = true,
  filteredSupplierId
}) => {
  const { suppliers, contentAllowedTypes } = useFirebaseClient();

  const [filteredContent, setFilteredContent] = useState([]);
  const [filteredContentByCategory, setFilteredContentByCategory] = useState(
    {}
  );
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);

  useEffect(() => {
    if (filteredSupplierId != null) {
      setSelectedSuppliers([filteredSupplierId]);
    }
  }, [filteredSupplierId]);

  useEffect(() => {
    let filteredContent = [];
    suppliers.forEach((supplier) => {
      if (selectedSuppliers.includes(supplier.id)) {
        Object.entries(supplier.content).forEach(([key, value]) => {
          let tempValue = { ...value };
          tempValue.supplier = supplier.details.name;
          filteredContent.push(tempValue);
        });
      } else if (selectedSuppliers.length === 0) {
        Object.entries(supplier.content).forEach(([key, value]) => {
          let tempValue = { ...value };
          tempValue.supplier = supplier.details.name;
          filteredContent.push(tempValue);
        });
      }
    });
    filteredContent.sort((a, b) => b.uploadDate - a.uploadDate);
    setFilteredContent(filteredContent);

    let filteredContentByCategory = {};
    filteredContent.forEach((element) => {
      if (filteredContentByCategory[element.type] === undefined) {
        filteredContentByCategory[element.type] = [element];
      } else {
        filteredContentByCategory[element.type].push(element);
      }
    });
    setFilteredContentByCategory(filteredContentByCategory);
  }, [suppliers, selectedSuppliers]);

  const onClickToggleSelectionForSupplier = (e, supplier) => {
    if (selectedSuppliers.includes(supplier.id)) {
      setSelectedSuppliers((prevState) =>
        prevState.filter((id) => id !== supplier.id)
      );
    } else {
      setSelectedSuppliers((prevState) => [...prevState, supplier.id]);
    }
  };

  const onClickShowAll = () => {
    setSelectedSuppliers([]);
  };

  const contentAreaCode = () => {
    if (displayInCategories) {
      let code = contentAllowedTypes.map((type) =>
        filteredContentByCategory[type] &&
        filteredContentByCategory[type].length > 0 ? (
          <Fragment key={type}>
            <CategoryTitle>{categoryTitles[type]}</CategoryTitle>
            <ContentArea>
              {filteredContentByCategory[type]
                ? filteredContentByCategory[type].map((item) => (
                    <ContentFragment key={item.url} itemDetails={item} />
                  ))
                : ""}
            </ContentArea>
          </Fragment>
        ) : (
          ""
        )
      );
      return code;
    } else {
      return (
        <ContentArea>
          {filteredContent.map((item) => (
            <ContentFragment key={item.url} itemDetails={item} />
          ))}
        </ContentArea>
      );
    }
  };

  return (
    <div>
      {showFilter ? (
        <Filter
          suppliers={suppliers}
          selectedSuppliers={selectedSuppliers}
          toggleSuppliers={onClickToggleSelectionForSupplier}
          showAll={onClickShowAll}
        />
      ) : null}

      <div className='contentAreaWrapper'>{contentAreaCode()}</div>
    </div>
  );
};

const CategoryTitle = styled.div`
  color: var(--dark-green);
  font-weight: 600;
  border-bottom: 1px solid var(--dark-green);
  margin-left: 20px;
`;

const ContentArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: 20px;
  margin-bottom: 30px;

  @media only screen and (max-width: 580px) {
    margin-left: 0;
    justify-content: center;
  }
`;

export default FilterableContentSection;
