import React, { useState } from "react";
import PublicPageLayout from "../pageFragments/pageLayouts/PublicPageLayout";
import Section from "../pageFragments/Section";
import { useFirebaseClient } from "../../context/useFirebaseClient";
import styled from "styled-components";
import SupplierInfoDisplay from "./manageContentAdmin/SupplierInfoDisplay";
import Modal from "../pageFragments/Modal";
import FilterableContentSection from "../pageFragments/content/FilterableContentSection";

const Suppliers = () => {
  const { suppliers } = useFirebaseClient();

  const [showSupplierContent, setShowSupplierContent] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState();

  const onViewContent = (supplier) => {
    setSelectedSupplier(supplier);
    setShowSupplierContent(true);
  };

  return (
    <PublicPageLayout>
      <Section title='SUPPLIERS'>
        {suppliers.map((supplier) => (
          <div key={supplier.id}>
            <SupplierInfoDisplay
              selectedSupplier={supplier}
              isAdmin={false}
              onViewContent={onViewContent}
            />
            <Divider />
          </div>
        ))}
      </Section>
      <Modal
        show={showSupplierContent}
        onClose={() => setShowSupplierContent(false)}
        maxWidth={"80%"}
        minWidth={"50%"}
      >
        {selectedSupplier &&
        Object.keys(selectedSupplier.content).length > 0 ? (
          <FilterableContentSection
            showFilter={false}
            filteredSupplierId={selectedSupplier ? selectedSupplier.id : null}
          />
        ) : (
          <NoContentDisplay>No Content to Display</NoContentDisplay>
        )}
      </Modal>
    </PublicPageLayout>
  );
};

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: lightgrey;
`;

const NoContentDisplay = styled.div`
  padding: 40px 0;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
`;

export default Suppliers;
