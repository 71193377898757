import React, { forwardRef } from "react";
import styled from "styled-components";

//PROPS: labelText, type, id, name, placeholder, marginBottom, className
const LabelledTextInput = forwardRef(
  (
    {
      labelText,
      type,
      id,
      name,
      capitalize,
      placeholder,
      marginBottom,
      className,
      shouldAutoFocus = false,
      onChange /*from react-hook-forms*/,
      onBlur /*from react-hook-forms*/,
      value,
      defaultValue /*from react-hook-forms*/
    },
    ref /*from react-hook-forms*/
  ) => {
    return (
      <Wrapper className={className}>
        <StyledLabel htmlFor={id}>{labelText}</StyledLabel>
        <StyledTextArea
          autoFocus={shouldAutoFocus}
          marginBottom={marginBottom}
          type={type}
          id={id}
          name={name}
          value={value}
          defaultValue={defaultValue}
          capitalize={capitalize}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          ref={ref}
        ></StyledTextArea>
      </Wrapper>
    );
  }
);

LabelledTextInput.defaultProps = {
  type: "text"
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledLabel = styled.label`
  text-transform: uppercase;
  color: black;
  padding-left: 5px;
  font-size: 10px;
  font-weight: 700;
  margin-left: 5px;
  margin-bottom: 2px;
`;

const TextArea = styled.textarea`
  border: 1px solid black;
  border-radius: 8px;
  padding: 10px;
  width: 100%;
  color: black;
  resize: vertical;

  &::placeholder {
    opacity: 40%;
  }
`;

const StyledTextArea = styled(TextArea)`
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : null)};
  text-transform: ${(props) => (props.capitalize ? "capitalize" : "none")};
`;

export default LabelledTextInput;
