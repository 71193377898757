import React, { useState, useEffect, Fragment } from "react";

import { useFirebaseAdmin } from "../../../context/useFirebaseAdmin";
import { nanoid } from "nanoid";
import AdminPageLayout from "../../pageFragments/pageLayouts/AdminPageLayout";
import Section from "../../pageFragments/Section";
import SelectSupplier from "./SelectSupplier";
import Modal from "../../pageFragments/Modal";
import ConfirmModal from "../../pageFragments/ConfirmModal";
import SupplierInfoInput from "./SupplierInfoInput";
import SupplierInfoDisplay from "./SupplierInfoDisplay";
import SupplierContentItem from "./SupplierContentItem";
import SupplierContentInput from "./SupplierContentInput";
import styled from "styled-components";

const categoryTitles = {
  Catalogue: "CATALOGUES",
  Flyer: "FLYERS",
  "Digital Media": "DIGITAL MEDIA"
};

const ManageContentAdmin = () => {
  const {
    suppliers,
    saveSupplier,
    uploadImage,
    deleteImage,
    deleteSupplier,
    contentAllowedTypes
  } = useFirebaseAdmin();

  const [selectedSupplier, setSelectedSupplier] = useState();
  const [selectedContentID, setSelectedContentID] = useState();
  const [sortedCurrentContent, setSortedCurrentContent] = useState([]);
  const [showNewSupplierModal, setShowNewSupplierModal] = useState(false);
  const [showContentEditModal, setShowContentEditModal] = useState(false);
  const [showDeleteContentModal, setShowDeleteContentModal] = useState(false);
  const [showDeleteSupplierModal, setShowDeleteSupplierModal] = useState(false);

  useEffect(() => {
    if (selectedSupplier && selectedSupplier.details) {
      let supplierContent = Object.keys(selectedSupplier.content).map((key) => {
        let content = selectedSupplier.content[key];
        content.id = key;
        return content;
      });
      supplierContent.sort((a, b) => b.uploadDate - a.uploadDate);

      let supplierContentByCategory = {};

      supplierContent.forEach((element) => {
        if (supplierContentByCategory[element.type] === undefined) {
          supplierContentByCategory[element.type] = [element];
        } else {
          supplierContentByCategory[element.type].push(element);
        }
      });

      setSortedCurrentContent(supplierContentByCategory);
    }
  }, [selectedSupplier]);

  //SUPPLIER INFO EDITING
  const createOrUpdateSupplier = () => {
    setShowNewSupplierModal(true);
  };

  const deleteCurrentSupplier = () => {
    setShowDeleteSupplierModal(true);
  };

  const onConfirmDeleteSupplier = async () => {
    setShowDeleteSupplierModal(false);
    try {
      if (
        selectedSupplier.details != null &&
        selectedSupplier.details.imageURL !== ""
      ) {
        await deleteImage(selectedSupplier.details.imageURL);
      }
      if (selectedSupplier.content != null) {
        await Object.keys(selectedSupplier.content).map(async (key) => {
          if (selectedSupplier.content[key].imageURL !== "") {
            await deleteImage(selectedSupplier.content[key].imageURL);
          }
        });
      }
    } catch (error) {
      console.log(error);
    }

    await deleteSupplier(selectedSupplier.id);
    setSelectedSupplier(undefined);
    setSortedCurrentContent([]);
  };

  const didCreateOrUpdateSupplier = async (supplierInfo, imageInfo) => {
    setShowNewSupplierModal(false);

    //set a standard object to collect supplier data to upload
    let supplier = {};
    supplier.id = selectedSupplier == null ? nanoid() : selectedSupplier.id;

    //if image exists upload it and get url
    let imageURL = "";
    if (imageInfo != null) {
      imageURL = await uploadImage(imageInfo, supplier.id, "supplier");
    } else {
      if (selectedSupplier != null) {
        imageURL = selectedSupplier.details.imageURL;
      }
    }

    if (selectedSupplier != null) {
      supplier.content = selectedSupplier.content;
    }
    supplier.details = {
      address: supplierInfo.address,
      description: supplierInfo.description,
      email: supplierInfo.emailAddresses,
      imageURL: imageURL,
      name: supplierInfo.supplierName,
      phone: supplierInfo.phoneNumbers,
      website: supplierInfo.website
    };

    supplier.content = {};

    await saveSupplier(supplier);
    setSelectedSupplier(supplier);
  };

  //SUPPLIER CONTENT EDITING
  const createOrUpdateContent = (id) => {
    setSelectedContentID(id == null ? nanoid() : id);
    setShowContentEditModal(true);
  };

  const deleteContent = (id) => {
    setSelectedContentID(id);
    setShowDeleteContentModal(true);
  };

  const onConfirmDeleteContent = async () => {
    setShowDeleteContentModal(false);
    console.log("Delete the content");

    const newSupplierContent = Object.keys(selectedSupplier.content).reduce(
      (object, key) => {
        if (key !== selectedContentID) {
          object[key] = selectedSupplier.content[key];
        }
        return object;
      },
      {}
    );
    const supplierToSave = {
      id: selectedSupplier.id,
      details: selectedSupplier.details,
      content: newSupplierContent
    };

    try {
      await deleteImage(selectedSupplier.content[selectedContentID].imageURL);
    } catch (error) {
      console.log(error);
    }
    setSelectedSupplier(supplierToSave);
    await saveSupplier(supplierToSave);
  };

  const didCreateOrUpdateContent = async (content, imageInfo) => {
    setShowContentEditModal(false);
    //if image exists upload it and get url
    let imageURL = "";
    if (imageInfo != null) {
      imageURL = await uploadImage(imageInfo, selectedContentID, "content");
    } else {
      if (
        selectedSupplier.content[selectedContentID] != null &&
        selectedSupplier.content[selectedContentID].imageURL != null
      ) {
        imageURL = selectedSupplier.content[selectedContentID].imageURL;
      }
    }

    content.imageURL = imageURL;
    content.uploadDate = selectedSupplier.content[selectedContentID]
      ? selectedSupplier.content[selectedContentID].uploadDate
      : new Date();

    let updatedSupplier = { ...selectedSupplier };
    updatedSupplier.content[selectedContentID] = content;

    setSelectedSupplier(updatedSupplier);

    await saveSupplier(updatedSupplier);
  };

  return (
    <AdminPageLayout>
      <Section>
        <SelectSupplier
          suppliers={suppliers}
          createOrUpdateSupplier={createOrUpdateSupplier}
          selectedSupplier={selectedSupplier}
          setSelectedSupplier={setSelectedSupplier}
        />
      </Section>
      <Modal
        show={showNewSupplierModal}
        onClose={() => setShowNewSupplierModal(false)}
        disableCloseOnOutsideClick={true}
      >
        <SupplierInfoInput
          selectedSupplier={selectedSupplier}
          onCreateOrUpdateSupplier={didCreateOrUpdateSupplier}
        />
      </Modal>
      <ConfirmModal
        show={showDeleteSupplierModal}
        onClose={() => setShowDeleteSupplierModal(false)}
        disableCloseOnOutsideClick={true}
        closeButtonHidden={true}
        message='Confirm Supplier Deletion'
        buttonTitle='Delete'
        onConfirm={onConfirmDeleteSupplier}
      ></ConfirmModal>
      {selectedSupplier !== undefined ? (
        <SupplierInfoDisplay
          isAdmin={true}
          selectedSupplier={selectedSupplier}
          onEditSupplier={createOrUpdateSupplier}
          onDeleteSupplier={deleteCurrentSupplier}
        />
      ) : null}
      <Section title='MANAGE CONTENT'>
        {selectedSupplier !== undefined ? (
          <NewContent>
            <Button onClick={() => createOrUpdateContent()}>
              ADD NEW CONTENT
            </Button>
          </NewContent>
        ) : null}
        <CurrentContent>
          {contentAllowedTypes.map((type) =>
            sortedCurrentContent[type] != null &&
            sortedCurrentContent[type].length > 0 ? (
              <Fragment key={type}>
                <CategoryTitle>{categoryTitles[type]}</CategoryTitle>
                <ContentArea>
                  {sortedCurrentContent[type] != null
                    ? sortedCurrentContent[type].map((item) => (
                        <SupplierContentItem
                          key={item.id}
                          content={item}
                          id={item.id}
                          onEdit={createOrUpdateContent}
                          onDelete={deleteContent}
                        />
                      ))
                    : ""}
                </ContentArea>
              </Fragment>
            ) : null
          )}
        </CurrentContent>
      </Section>
      <Modal
        show={showContentEditModal}
        onClose={() => setShowContentEditModal(false)}
        disableCloseOnOutsideClick={true}
      >
        <SupplierContentInput
          contentAllowedTypes={contentAllowedTypes}
          selectedContent={
            selectedSupplier && selectedSupplier.content
              ? selectedSupplier.content[selectedContentID]
                ? selectedSupplier.content[selectedContentID]
                : {}
              : {}
          }
          onCreateOrUpdateContent={didCreateOrUpdateContent}
        />
      </Modal>
      <ConfirmModal
        show={showDeleteContentModal}
        onClose={() => setShowDeleteContentModal(false)}
        disableCloseOnOutsideClick={true}
        closeButtonHidden={true}
        message='Confirm Content Deletion'
        buttonTitle='Delete'
        onConfirm={onConfirmDeleteContent}
      ></ConfirmModal>
    </AdminPageLayout>
  );
};

const NewContent = styled.div`
  padding: 20px 0;
`;

const Button = styled.button`
  border: none;
  border-radius: 8px;
  padding: 3px 10px;
  margin: 2px;
  font-family: "Montserrat", sans-serif;
  font-size: 1.1em;
  color: white;
  background-color: black;
  cursor: pointer;
`;

const CurrentContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
`;

const CategoryTitle = styled.div`
  color: var(--dark-green);
  font-weight: 600;
  border-bottom: 1px solid var(--dark-green);
`;

const ContentArea = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  @media only screen and (max-width: 580px) {
    margin-left: 0;
    justify-content: center;
  }
`;

export default ManageContentAdmin;
