import React from "react";
import { stack as Menu } from "react-burger-menu";
import { NavLink } from "react-router-dom";
import "../../customCSS/burgerStyle.css";
import styled from "styled-components";

const Nav = ({ links, admin }) => {
  return (
    <StyledNav>
      <ul>
        {links.map((link) => (
          <li key={link.url}>
            <NavLink exact activeClassName='nav-selected' to={link.url}>
              {link.name}
            </NavLink>
          </li>
        ))}
        {admin != null ? <li>{admin}</li> : null}
      </ul>
      <BurgerNav>
        <Menu
          right
          itemListElement='div'
          customBurgerIcon={<img src='burger.svg' alt='burgerMenu' />}
          disableAutoFocus
        >
          {links.map((link) => (
            <NavLink
              key={link.url}
              exact
              activeClassName='nav-selected'
              to={link.url}
            >
              {link.name}
            </NavLink>
          ))}
          {admin != null ? admin : null}
        </Menu>
      </BurgerNav>
    </StyledNav>
  );
};

const StyledNav = styled.nav`
  font-weight: 800;
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  align-items: center;
  @media only screen and (max-width: 959px) {
    flex-grow: 0;
  }

  & > ul {
    list-style: none;
    display: flex;
    align-items: center;
    color: white;
    @media only screen and (max-width: 959px) {
      display: none;
    }

    & > li {
      padding-left: 20px;
      padding-right: 20px;

      &:hover {
        cursor: pointer;
      }

      & > *.nav-selected {
        background-color: white;
        border-radius: 10px;
        padding: 5px 10px;
        color: var(--dark-green);
      }
    }
  }
`;

const BurgerNav = styled.div`
  display: none;

  @media only screen and (max-width: 959px) {
    display: inherit;
    font-size: 30px;
    width: 55px;
    height: 55px;
  }
`;

export default Nav;
