import React, { Fragment } from "react";
import Header from "../Header";

const PublicPageLayout = ({ children }) => {
  const links = [
    { name: "HOME", url: "/" },
    { name: "SUPPLIERS", url: "/suppliers" },
    { name: "CONTACT", url: "/contact" }
  ];

  return (
    <Fragment>
      <Header links={links} />
      {children}
    </Fragment>
  );
};

export default PublicPageLayout;
