import React from "react";
import styled from "styled-components";

const LoginContentWrapper = ({ title, children }) => {
  return (
    <ContentWrapper>
      <AdidasHeader>
        <Image src='bha_logo.svg'></Image>
        {title ? <Title>{title}</Title> : ""}
      </AdidasHeader>
      {children}
    </ContentWrapper>
  );
};

const ContentWrapper = styled.div`
  padding-top: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 95%;
`;

const AdidasHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10vh;
  font-size: 22px;
  font-weight: 800;
  color: black;
`;

const Image = styled.img`
  width: 200px;
  margin-bottom: 10px;
`;

const Title = styled.div`
  padding-top: 10px;
  font-size: 20px;
  font-weight: 800;
  text-align: center;
  color: black;
`;

export default LoginContentWrapper;
