import React from "react";
import styled from "styled-components";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

const SelectSupplier = ({
  suppliers,
  createOrUpdateSupplier,
  selectedSupplier,
  setSelectedSupplier
}) => {
  const dropdownSuppliers = () => {
    let data = [];
    suppliers.forEach((supplier) => {
      let supplierData = {
        value: supplier["id"],
        label: supplier["details"]["name"]
      };
      data.push(supplierData);
    });
    data.push({ value: "new", label: "NEW SUPPLIER" });
    return data;
  };

  const didSelectSupplier = (values) => {
    const supplier = suppliers.find((element) => {
      return element.id === values.value;
    });
    if (supplier === undefined) {
      setSelectedSupplier();
      createOrUpdateSupplier();
    } else {
      setSelectedSupplier(supplier);
    }
  };

  return (
    <SupplierSelectSection>
      <div>
        <SupplierSelectTitle>SELECT A SUPPLIER TO MANAGE:</SupplierSelectTitle>
        <Dropdown
          options={dropdownSuppliers()}
          onChange={(value) => didSelectSupplier(value)}
          value={
            selectedSupplier !== undefined
              ? {
                  value: selectedSupplier.id,
                  label: selectedSupplier["details"]["name"]
                }
              : undefined
          }
          placeholder='SUPPLIER...'
        />
      </div>
    </SupplierSelectSection>
  );
};

const SupplierSelectSection = styled.div`
  padding: 5px 2%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SupplierSelectTitle = styled.div`
  text-align: center;
  color: black;
  font-size: 12px;
  font-weight: 700;
`;

export default SelectSupplier;
