import React, { useRef, useState, useEffect, Fragment } from "react";
import PersonRoundedIcon from "@material-ui/icons/PersonRounded";
import styled from "styled-components";
import { useAuth } from "../../context/useAuth";

const AdminDropdownMenu = ({ userName }) => {
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useState(false);

  const { logout } = useAuth();

  //Handle User Clicks Outside of the Menu
  useEffect(() => {
    const pageClickEvent = (e) => {
      if (
        dropdownRef.current !== null &&
        !dropdownRef.current.contains(e.target)
      ) {
        setIsActive(!isActive);
      }
    };

    // If the item is active (ie open) then listen for clicks
    if (isActive) {
      window.addEventListener("click", pageClickEvent);
    }

    return () => {
      window.removeEventListener("click", pageClickEvent);
    };
  }, [isActive]);

  const onClickUserMenu = () => setIsActive(!isActive);

  return (
    <Fragment>
      <DropDownContainer>
        <MenuTrigger onClick={onClickUserMenu}>
          {/* <span>{user.displayName.match(/\b(\w)/g).join("")}</span> */}
          <PersonRoundedIcon />
        </MenuTrigger>
        <Nav ref={dropdownRef} isActive={isActive}>
          {/* <NameHeader>{user.displayName}</NameHeader> */}
          <ul>
            <li onClick={logout}>Logout</li>
          </ul>
        </Nav>
      </DropDownContainer>
    </Fragment>
  );
};

const DropDownContainer = styled.div`
  position: relative;
`;

const MenuTrigger = styled.button`
  background: none;
  color: white;
  cursor: pointer;
  border: none;
  font-weight: 800;
  border-radius: 8px;
  border: 3px solid white;
  padding: 1px 4px;
  transition: 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    padding-right: 0px;
  }

  &:hover {
    color: var(--light-green);
    border: 3px solid var(--light-green);
  }
`;

const Nav = styled.nav`
  text-align: center;
  background: #ffffff;
  border-radius: 8px;
  position: absolute;
  top: 40px;
  right: 0;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  white-space: nowrap;
  overflow: hidden;

  opacity: ${(props) => (props.isActive ? 1 : 0)};
  visibility: ${(props) => (props.isActive ? "visible" : "hidden")};
  transform: ${(props) =>
    props.isActive ? "translateY(0)" : "translateY(-20px)"};
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    border-bottom: 1px solid #dddddd;
    padding: 15px 20px;
    cursor: pointer;
    color: black;

    &:hover {
      background-color: var(--light-green);
      color: white;
    }
    a {
      text-decoration: none;
      color: #333333;
      padding: 15px 20px;
      display: block;
    }
  }
`;

export default AdminDropdownMenu;
