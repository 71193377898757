import React, { useState } from "react";
import TimePicker from "react-time-picker";
import "../../../customCSS/timePickerStyle.css";
import Toggle from "react-toggle";
import "../../../customCSS/toggleStyle.css";
import styled from "styled-components";
import { useFirebaseAdmin } from "../../../context/useFirebaseAdmin";

const ScheduleManager = ({ officeStatus, onClose }) => {
  const { saveOfficeStatus } = useFirebaseAdmin();

  const [newSchedule, setNewSchedule] = useState(officeStatus);

  const handleScheduleTimeChange = (t, day, openClose) => {
    let hoursMinutes = t.split(":");
    const time = new Date().setHours(hoursMinutes[0], hoursMinutes[1]);
    let schedule = { ...newSchedule };
    schedule.schedule[day][openClose] = new Date(time);
    setNewSchedule(schedule);
  };

  const handleCurrentStatusToggle = (e, index) => {
    let schedule = { ...newSchedule };
    schedule.schedule[index].isOpen = e.target.checked;
    setNewSchedule(schedule);
  };

  const handleSaveButtonPress = async () => {
    await saveOfficeStatus(newSchedule);
    onClose();
  };

  return (
    <ScheduleWrapper>
      {newSchedule != null && newSchedule.schedule != null
        ? newSchedule.schedule.map((dayData, index) =>
            index !== 0 && index !== 6 ? (
              <DaySection key={index}>
                <span>{dayData.name}</span>
                <ToggleStatus>
                  <Toggle
                    index={index}
                    checked={dayData.isOpen}
                    onChange={(e) => handleCurrentStatusToggle(e, index)}
                  />
                  <span>{dayData.isOpen ? `OPEN` : `CLOSED`}</span>
                </ToggleStatus>
                {dayData.isOpen ? (
                  <OpenCloseTimes>
                    <TimePicker
                      value={dayData.open}
                      onChange={(time) =>
                        handleScheduleTimeChange(time, index, "open")
                      }
                      format={"h:mm a"}
                      hourPlaceholder='--'
                      minutePlaceholder='--'
                      clearIcon={null}
                      disableClock={true}
                    />
                    <span className='spacer'>to</span>
                    <TimePicker
                      value={dayData.close}
                      onChange={(time) =>
                        handleScheduleTimeChange(time, index, "close")
                      }
                      format={"h:mm a"}
                      hourPlaceholder='--'
                      minutePlaceholder='--'
                      clearIcon={null}
                      disableClock={true}
                    />
                  </OpenCloseTimes>
                ) : null}
              </DaySection>
            ) : null
          )
        : null}
      {/* {hasChanges ? ( */}
      <ButtonWrapper>
        <SaveButton onClick={handleSaveButtonPress}>SAVE</SaveButton>
      </ButtonWrapper>
      {/* ) : null} */}
    </ScheduleWrapper>
  );
};

const ScheduleWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: black;
`;

const DaySection = styled.div`
  padding: 10px 0;
  border-bottom: 1px solid black;
  & span {
    color: black;
    font-weight: 800;
  }
`;

const ToggleStatus = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;

  & span {
    font-weight: 600;
    margin-left: 5px;
  }
`;

const OpenCloseTimes = styled.div`
  display: flex;
  align-items: center;

  .spacer {
    padding: 0 4px;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
`;

const SaveButton = styled.button`
  padding: 5px 10px;
  background-color: var(--dark-green);
  border: none;
  color: white;
  border-radius: 10px;
  font-weight: 700;
  font-size: 20px;
  cursor: pointer;
`;

export default ScheduleManager;
