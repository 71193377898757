import React from "react";
import styled from "styled-components";

const SectionHeader = ({ title }) => {
  return <SectionTitle>{title}</SectionTitle>;
};

const SectionTitle = styled.div`
  color: rgb(48, 48, 48);
  font-weight: 800;
  font-size: 24px;
  border-bottom: solid;
  border-width: 1px;
`;

export default SectionHeader;
