import React from "react";
import SectionHeader from "./SectionHeader";
import styled from "styled-components";

const Section = ({ title, children, className }) => {
  return (
    <ContentSection className={className}>
      {title !== null && title !== undefined ? (
        <SectionHeader title={title} />
      ) : null}

      {children}
    </ContentSection>
  );
};

const ContentSection = styled.div`
  margin-top: 55px;
  padding: 30px 10px 0px;
`;

export default Section;
