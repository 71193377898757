import React, { useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { useDropzone } from "react-dropzone";
import Jimp from "jimp/es";
import LabelledTextInput from "../../pageFragments/LabelledTextInput";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

const SupplierContentInput = ({
  selectedContent,
  contentAllowedTypes,
  onCreateOrUpdateContent
}) => {
  const [imageInfo, setImageInfo] = useState();
  const [image, setImage] = useState();
  const [selectedContentType, setSelectedContentType] = useState(
    selectedContent.type
  );

  const onDrop = useCallback((acceptedFiles) => {
    console.log(acceptedFiles);
    setImageInfo(acceptedFiles[0]);

    //Read the image from file upload to display
    var reader = new FileReader();
    reader.onload = async function (e) {
      var contents = e.target.result;
      try {
        const image = await Jimp.read(contents);
        image.background(0xffffffff);
        image.contain(250, 250);
        image.quality(30);
        setImageInfo(await image.getBufferAsync(Jimp.MIME_JPEG));
        const resizedImage = await image.getBase64Async(Jimp.MIME_JPEG);
        setImage(resizedImage);
      } catch (error) {
        console.log(error);
      }
    };
    reader.readAsDataURL(acceptedFiles[0]);
  }, []);

  const didSelectContentType = (value) => {
    setSelectedContentType(value);
  };

  const onSubmit = (content) => {
    content.type = selectedContentType;
    onCreateOrUpdateContent(content, imageInfo);
  };

  //HOOKS
  const { register, handleSubmit } = useForm();

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1
  });

  return (
    <Container>
      <Dropzone {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        {(selectedContent == null ||
          Object.keys(selectedContent).length === 0) &&
        imageInfo == null ? (
          <p>Drop a 500px x 500px JPG Here</p>
        ) : imageInfo !== undefined ? (
          <img src={image} alt='' />
        ) : (
          <img src={selectedContent.imageURL} alt='' />
        )}
      </Dropzone>
      <Dropdown
        options={contentAllowedTypes.map((type) => ({
          value: type,
          label: type
        }))}
        onChange={(value) => didSelectContentType(value.value)}
        value={selectedContentType}
        placeholder='TYPE...'
      />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <LabelledTextInput
          labelText={"Name"}
          placeholder={"Name"}
          defaultValue={
            selectedContent !== undefined ? selectedContent.name : undefined
          }
          capitalized={true}
          marginBottom={"10px"}
          {...register("name")}
        />
        <LabelledTextInput
          labelText={"URL"}
          placeholder={"URL"}
          defaultValue={
            selectedContent !== undefined ? selectedContent.url : undefined
          }
          capitalized={true}
          marginBottom={"10px"}
          {...register("url")}
        />
        {selectedContentType != null ? (
          <SubmitButton type='submit' value='SAVE' />
        ) : null}
      </Form>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: black;

  @media screen and (min-width: 550px) {
    width: 500px;
  }

  @media screen and (max-width: 550px) {
    width: 100%;
  }
`;

const Dropzone = styled.div`
  border: 1px solid black;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  overflow: none;

  &.dropzone {
    border: 1px solid black;
    background-color: white;
    border-radius: 15px;
    padding: 5px;
    overflow: hidden;
    cursor: pointer;
  }

  & > input {
    width: 100%;
    height: 100%;
  }

  & > p {
    text-align: center;
    color: black;
  }

  & > img {
    width: 100%;
    height: 100%;
  }
`;

const Form = styled.form`
  color: black;
  padding-top: 20px;
`;

const SubmitButton = styled.input`
  border: none;
  border-radius: 8px;
  padding: 3px 10px;
  margin: 2px;
  font-family: "Montserrat", sans-serif;
  font-size: 1.1em;
  color: white;
  background-color: black;
  cursor: pointer;
`;

export default SupplierContentInput;
