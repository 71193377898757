import React from "react";
import PublicPageLayout from "../pageFragments/pageLayouts/PublicPageLayout";
import Section from "../pageFragments/Section";
import styled from "styled-components";

const Contact = () => {
  return (
    <PublicPageLayout>
      <Section title='CONTACT US'>
        <Wrapper>
          <InnerWrapper>
            <Info>
              <Details>
                <Title>Main Phone: </Title>
                <Data>+1 (780) 444-6396</Data>
              </Details>
              <Details>
                <Title>General Email: </Title>
                <Data>harrisgroup@bhainc.ca</Data>
              </Details>
              <Details>
                <Title>Address: </Title>
                <Data>
                  17352-107 Ave
                  <br />
                  Edmonton, AB T5S 1E9
                </Data>
              </Details>
            </Info>
            <MapContainer>
              <iframe
                title={"Google Maps"}
                src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1578.3032673920454!2d-113.62328704460661!3d53.553805266732674!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x53a02121af86b80f%3A0x83e9ab70b69fadf0!2sBrian%20Harris%20Agencies%20Inc!5e0!3m2!1sen!2sca!4v1630079324189!5m2!1sen!2sca'
                width='100%'
                height='100%'
                style={{ border: 0 }}
                loading='lazy'
              ></iframe>
            </MapContainer>
          </InnerWrapper>
        </Wrapper>
      </Section>
    </PublicPageLayout>
  );
};

const Wrapper = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InnerWrapper = styled.div`
  width: 80%;

  @media screen and (max-width: 550px) {
    width: 100%;
  }
`;

const Info = styled.div`
  padding: 20px 0 50px 0;

  @media screen and (max-width: 550px) {
    padding: 0 0 20px 0;
  }
`;

const Details = styled.div`
  padding: 5px 0px;
  display: flex;
  align-items: stretch;

  @media screen and (max-width: 550px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Title = styled.div`
  color: black;
  font-size: 20px;
  font-weight: bold;
  padding-right: 10px;
`;

const Data = styled.div`
  padding-top: 2px;
  color: black;
  font-size: 18px;
`;

const MapContainer = styled.div`
  width: 100%;
  height: 400px;

  @media screen and (max-width: 550px) {
    height: 300px;
  }
`;

export default Contact;
