import React, { Fragment } from "react";
import { useFirebaseClient } from "../../context/useFirebaseClient";
import {
  getMondayDate,
  isFirstTimeAfterSecondTime
} from "../helpers/DateHelpers";
import styled from "styled-components";

const OfficeStatus = ({ className, admin = false }) => {
  const { officeStatus } = useFirebaseClient();

  //CODE FRAGMENTS FOR RETURN STATEMENT
  let currentStatus = () => {
    if (officeStatus != null) {
      const currentDay = new Date().getDay();
      const outsideBusinessHours =
        currentDay === 0 ||
        currentDay === 6 ||
        isFirstTimeAfterSecondTime(
          new Date(),
          officeStatus.schedule[currentDay].close
        ) ||
        isFirstTimeAfterSecondTime(
          officeStatus.schedule[currentDay].open,
          new Date()
        )
          ? true
          : false;
      if (officeStatus.currentStatus.isOpen && !outsideBusinessHours) {
        return <p className='status-open'>OPEN</p>;
      } else {
        if (outsideBusinessHours) {
          return <p className='status-closed'>CLOSED</p>;
        } else {
          let returnTime = officeStatus.currentStatus.returnTime;
          return (
            <Fragment>
              <p className='status-closed'>BACK AT:</p>
              <p className='time'>{`${returnTime.toLocaleTimeString(["en-US"], {
                hour: "numeric",
                minute: "2-digit"
              })}`}</p>
            </Fragment>
          );
        }
      }
    } else {
    }
  };

  let openTime = (day) => {
    if (officeStatus) {
      if (officeStatus["schedule"][day]["isOpen"]) {
        const open = officeStatus["schedule"][day]["open"].toLocaleTimeString(
          ["en-US"],
          {
            hour: "numeric",
            minute: "2-digit"
          }
        );
        const close = officeStatus["schedule"][day]["close"].toLocaleTimeString(
          ["en-US"],
          {
            hour: "numeric",
            minute: "2-digit"
          }
        );
        return `${open}-${close}`;
      } else {
        return "CLOSED";
      }
    } else {
      return "";
    }
  };
  return (
    <OfficeHoursWrapper className={className}>
      <CurrentStatusContainer>
        <p>
          <span>(LIVE) </span>OFFICE STATUS:
        </p>
        <CurrentStatus>{currentStatus()}</CurrentStatus>
      </CurrentStatusContainer>
      <Spacer></Spacer>
      <Schedule>
        <p>{`OFFICE SCHEDULE: WEEK OF ${getMondayDate().toUpperCase()}`}</p>
        <ul id='schedule-day-blocks'>
          {officeStatus != null
            ? officeStatus.schedule.map((dayData, index) =>
                index !== 0 && index !== 6 ? (
                  <DayOfWeekBlock key={index} isOpen={dayData.isOpen}>
                    <Day>{dayData.name}</Day>
                    <Time>{openTime(index)}</Time>
                  </DayOfWeekBlock>
                ) : null
              )
            : null}
        </ul>
      </Schedule>
    </OfficeHoursWrapper>
  );
};

const OfficeHoursWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 65px;
  padding-left: 10px;
  padding-right: 10px;

  @media only screen and (max-width: 560px) {
    text-align: center;
    flex-direction: column;
  }
`;

const CurrentStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: flex-start;

  & > p {
    color: rgb(48, 48, 48);
    font-weight: 700;
    font-size: 12px;
    padding-bottom: 3px;
    & > span {
      color: var(--mid-green);
    }
  }
`;

const CurrentStatus = styled.div`
  width: 100%;
  background-color: rgb(48, 48, 48);
  border-radius: 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;

  @media only screen and (max-width: 560px) {
    padding: 5px 0px;
  }

  & > p.status-open {
    font-weight: 800;
    font-size: 22px;
    color: var(--light-green);
  }

  & > p.status-closed {
    font-weight: 800;
    font-size: 20px;
    color: orange;
    padding: 0;
  }

  & > p.time {
    color: white;
    padding: 0;
    font-size: 14px;
  }
`;

const Spacer = styled.div`
  background-color: var(--dark-green);
  width: 2px;
  border-radius: 2px;
  margin-left: 20px;
  margin-right: 10px;

  @media only screen and (max-width: 560px) {
    height: 2px;
    border-radius: 2px;
    margin: 20px 0;
    width: 100%;
  }
`;

const Schedule = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;

  @media only screen and (max-width: 560px) {
    flex-grow: 0;
  }

  & > p {
    color: rgb(48, 48, 48);
    font-weight: 700;
    font-size: 12px;
    padding-bottom: 3px;
  }

  & > ul {
    list-style: none;
    width: 100%;
    display: flex;

    @media only screen and (max-width: 560px) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
`;

const DayOfWeekBlock = styled.li`
  margin: 5px 5px;
  padding: 5px 10px;
  background-color: ${(props) => (props.isOpen ? "rgb(48, 48, 48)" : "orange")};
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 1 0px;

  @media only screen and (max-width: 560px) {
    flex-grow: 0;
    min-width: 100px;
  }
`;

const Day = styled.div`
  color: var(--light-green);
  font-size: 14px;
  padding-bottom: 0px;
`;

const Time = styled.div`
  color: white;
  font-size: 12px;
`;

export default OfficeStatus;
