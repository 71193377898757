import { createContext, useState, useEffect, useContext } from "react";
import { cloneDeep } from "lodash";
import { useAuth } from "./useAuth";
import { getApp } from "firebase/app";
import {
  deleteObject,
  ref,
  getStorage,
  uploadBytes,
  getDownloadURL
} from "firebase/storage";

import {
  getFirestore,
  collection,
  setDoc,
  onSnapshot,
  Timestamp,
  getDoc,
  doc,
  deleteDoc
} from "firebase/firestore";

//CREATE AUTH CONTEXT
const FirebaseContextAdmin = createContext();

const firStorage = getStorage(getApp());

//AUTH CONTEXT PROVIDER TO WRAP AROUND APP
const FirebaseContextProviderAdmin = ({ children }) => {
  //INTERNAL
  const { user } = useAuth();
  const db = getFirestore();

  //CONTEXT STATE
  const [officeStatus, setOfficeStatus] = useState(null);
  const [suppliers, setSuppliers] = useState([]);
  const [contentAllowedTypes, setContentAllowedTypes] = useState([]);

  //LISTENERS
  useEffect(() => {
    getDoc(doc(db, "site-helpers", "supplier-standard-info")).then((doc) => {
      setContentAllowedTypes(doc.data()["allowedContentTypes"]);
    });
  }, [db]);

  useEffect(() => {
    let unsubscribe;
    unsubscribe = onSnapshot(
      doc(db, "office-info", "status-schedule"),
      (doc) => {
        //Deconstruct Doc to translate Timestamps to JS Dates
        const status = {
          currentStatus: {
            isOpen: doc.data().currentStatus.isOpen,
            returnTime: doc.data().currentStatus.returnTime.toDate()
          },
          schedule: []
        };
        doc.data().schedule.map((day) =>
          status.schedule.push({
            isOpen: day.isOpen,
            name: day.name,
            open: day.open.toDate(),
            close: day.close.toDate()
          })
        );
        setOfficeStatus(status);
      }
    );
    return () => {
      unsubscribe();
    };
  }, [db]);

  useEffect(() => {
    let unsubscribe;
    unsubscribe = onSnapshot(collection(db, "suppliers"), (snapshot) => {
      const suppliers = [];
      snapshot.forEach((doc) => {
        let supplierInfo = doc.data();
        supplierInfo["id"] = doc.id;
        //Deconstruct Doc to translate Timestamps to JS Dates
        Object.keys(supplierInfo.content).map(
          (key) =>
            (supplierInfo.content[key].uploadDate =
              supplierInfo.content[key].uploadDate.toDate())
        );

        suppliers.push(supplierInfo);
      });
      suppliers.sort((a, b) =>
        a.details.name > b.details.name
          ? 1
          : b.details.name > a.details.name
          ? -1
          : 0
      );
      setSuppliers(suppliers);
    });
    return () => {
      unsubscribe();
    };
  }, [db, user]);

  //DATABASE WRITE METHODS
  const saveOfficeStatus = async (data) => {
    let newSchedule = {
      currentStatus: {
        isOpen: data.currentStatus.isOpen,
        returnTime: Timestamp.fromDate(data.currentStatus.returnTime)
      },
      schedule: []
    };
    data.schedule.map((day) =>
      newSchedule.schedule.push({
        isOpen: day.isOpen,
        name: day.name,
        open: Timestamp.fromDate(day.open),
        close: Timestamp.fromDate(day.close)
      })
    );
    return await setDoc(doc(db, "office-info", "status-schedule"), data);
  };

  const saveSupplier = async (data) => {
    let dataCopy = cloneDeep(data);

    await Object.keys(dataCopy.content).map(
      (key) =>
        (dataCopy.content[key].uploadDate = Timestamp.fromDate(
          data.content[key].uploadDate
        ))
    );
    return await setDoc(doc(db, "suppliers", data.id), {
      details: dataCopy.details,
      content: dataCopy.content
    });
  };

  const deleteSupplier = async (id) => {
    await deleteDoc(doc(db, "suppliers", id));
  };

  const uploadImage = async (image, id, type) => {
    const folderName =
      type === "supplier" ? "supplier-images" : "content-images";
    const imageRef = ref(firStorage, `${folderName}/${id}.jpg`);

    const metadata = {
      contentType: "image/jpeg"
    };

    const snapshot = await uploadBytes(imageRef, image, metadata);
    return await getDownloadURL(snapshot.ref);
  };

  const deleteImage = async (imageURL) => {
    let imageRef = ref(firStorage, imageURL);
    return await deleteObject(imageRef);
  };

  //RETURN STATEMENT FOR COMPONENT
  return (
    <FirebaseContextAdmin.Provider
      value={{
        officeStatus,
        suppliers,
        contentAllowedTypes,
        saveOfficeStatus,
        saveSupplier,
        uploadImage,
        deleteImage,
        deleteSupplier
      }}
    >
      {children}
    </FirebaseContextAdmin.Provider>
  );
};

export default FirebaseContextProviderAdmin;

//CREATE A HOOK TO BE USED ON A CONSUMER COMPONENT TO READ AUTH VARIABLES AND METHODS
export const useFirebaseAdmin = () => useContext(FirebaseContextAdmin);
