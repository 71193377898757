import React, { useState, useEffect } from "react";
import styled from "styled-components";
import AdminPageLayout from "../../pageFragments/pageLayouts/AdminPageLayout";
import Section from "../../pageFragments/Section";
import OfficeStatusAdmin from "../../pageFragments/OfficeStatusAdmin";
import Toggle from "react-toggle";
import "../../../customCSS/officeStatusAdminStyle.css";
import "../../../customCSS/toggleStyle.css";
import TimePicker from "react-time-picker";
import "../../../customCSS/timePickerStyle.css";
import { useFirebaseAdmin } from "../../../context/useFirebaseAdmin";
import ScheduleManager from "./ScheduleManager";
import Modal from "../../pageFragments/Modal";

const OfficeStatusManager = () => {
  const { officeStatus, saveOfficeStatus } = useFirebaseAdmin();

  const [newSchedule, setNewSchedule] = useState({
    currentStatus: { isOpen: true, returnTime: new Date() },
    schedule: []
  });
  const [hasChanges, setHasChanges] = useState(false);
  const [showScheduleChangerModal, setShowScheduleChangerModal] =
    useState(false);

  useEffect(() => {
    if (officeStatus != null) {
      setNewSchedule(officeStatus);
    }
  }, [officeStatus]);

  const handleSaveButtonPress = async () => {
    await saveOfficeStatus(newSchedule);
    setHasChanges(false);
  };

  const handleCurrentStatusToggle = (e) => {
    setHasChanges(true);
    const now = new Date();
    const now_20 = new Date(
      now.setMinutes(Math.ceil((now.getMinutes() + 20) / 5) * 5)
    );
    setNewSchedule((prevState) => ({
      ...prevState,
      currentStatus: {
        ...prevState.currentStatus,
        isOpen: e.target.checked,
        returnTime: now_20
      }
    }));
  };

  const handleCurrentStatusTimeChange = (t) => {
    setHasChanges(true);
    let hoursMinutes = t.split(":");
    const time = new Date().setHours(hoursMinutes[0], hoursMinutes[1]);
    setNewSchedule((prevState) => ({
      ...prevState,
      currentStatus: {
        ...prevState.currentStatus,
        returnTime: new Date(time)
      }
    }));
  };

  return (
    <AdminPageLayout>
      <Section title={"OFFICE STATUS"}>
        <div className='admin-current-status-section'>
          <div className='first-line'>
            <div className='current-status-label'>CURRENT STATUS:</div>
            <Toggle
              id='current-status-toggle'
              checked={newSchedule.currentStatus.isOpen}
              onChange={handleCurrentStatusToggle}
            />
            <div
              className={`open-close-label ${
                newSchedule.currentStatus.isOpen ? `open` : `closed`
              }`}
            >
              {newSchedule.currentStatus.isOpen ? `OPEN` : `CLOSED`}
            </div>
          </div>
          {!newSchedule.currentStatus.isOpen ? (
            <div className='second-line'>
              <div className='return-time-label'>RETURN TIME:</div>
              <TimePicker
                className={"current-status-time-picker"}
                value={newSchedule.currentStatus.returnTime}
                onChange={(time) => handleCurrentStatusTimeChange(time)}
                format={"h:mm a"}
                hourPlaceholder='--'
                minutePlaceholder='--'
                clearIcon={null}
                disableClock={true}
              />
            </div>
          ) : null}
          {hasChanges ? (
            <StyledSaveButton onClick={handleSaveButtonPress}>
              SAVE
            </StyledSaveButton>
          ) : null}
        </div>
      </Section>
      <StyledSection title={"SCHEDULE FOR WEEK"}>
        <WeekSchedule />
        <ChangeScheduleSection>
          <ChangeScheduleButton
            onClick={() => setShowScheduleChangerModal(true)}
          >
            CHANGE SCHEDULE
          </ChangeScheduleButton>
        </ChangeScheduleSection>
      </StyledSection>
      <Modal
        show={showScheduleChangerModal}
        onClose={() => setShowScheduleChangerModal(false)}
        disableCloseOnOutsideClick={true}
        closeButtonHidden={true}
      >
        <ScheduleManager
          officeStatus={newSchedule}
          onClose={() => setShowScheduleChangerModal(false)}
        />
      </Modal>
    </AdminPageLayout>
  );
};

const StyledSection = styled(Section)`
  margin-top: 10px;
`;

const WeekSchedule = styled(OfficeStatusAdmin)`
  margin-top: 25px;
`;

const ChangeScheduleSection = styled.div`
  display: flex;
  justify-content: center;
  margin: 30px 0;
`;

const StyledSaveButton = styled.button`
  cursor: pointer;
`;

const ChangeScheduleButton = styled.button`
  padding: 5px 10px;
  background-color: var(--dark-green);
  border: none;
  color: white;
  border-radius: 10px;
  font-weight: 700;
  font-size: 20px;
  cursor: pointer;
`;

export default OfficeStatusManager;
