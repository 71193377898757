import React from "react";
import styled from "styled-components";

import Modal from "./Modal";

const ConfirmModal = ({
  show,
  onClose,
  onConfirm,
  title,
  message,
  buttonTitle,
  ...props
}) => {
  return (
    <Modal title={title} show={show} onClose={onClose} {...props}>
      <Container>
        <MessageWrapper>{message}</MessageWrapper>
        <ButtonWrapper>
          <CancelButton onClick={onClose} type='button'>
            Cancel
          </CancelButton>
          <ConfirmButton onClick={onConfirm} primary type='button'>
            {buttonTitle}
          </ConfirmButton>
        </ButtonWrapper>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  padding: 30px;
`;

const MessageWrapper = styled.div`
  text-align: center;
  color: black;
`;

const ButtonWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: center;
`;

const ConfirmButton = styled.button`
  border: none;
  border-radius: 8px;
  padding: 3px 10px;
  margin: 4px;
  font-family: "Montserrat", sans-serif;
  font-size: 1.1em;
  color: white;
  background-color: black;
  cursor: pointer;
`;

const CancelButton = styled.button`
  border: 1px solid black;
  border-radius: 8px;
  padding: 3px 10px;
  margin: 4px;
  font-family: "Montserrat", sans-serif;
  font-size: 1.1em;
  color: black;
  background-color: white;
  cursor: pointer;
`;

export default ConfirmModal;
