import React from "react";
import styled from "styled-components";

const Filter = ({ suppliers, selectedSuppliers, toggleSuppliers, showAll }) => {
  return (
    <SectionFilter>
      <p>FILTER:</p>
      <ul>
        <li
          onClick={showAll}
          className={
            selectedSuppliers.length === 0 ? "filter-supplier-selected" : ""
          }
        >
          <div>SHOW ALL</div>
        </li>
        {suppliers.map((supplier) => (
          <li
            key={supplier.id}
            onClick={(e) => toggleSuppliers(e, supplier)}
            className={
              selectedSuppliers.includes(supplier.id)
                ? "filter-supplier-selected"
                : ""
            }
          >
            <div>{supplier.details.name.toUpperCase()}</div>
            {selectedSuppliers.includes(supplier.id) ? (
              <i className='fas fa-times'></i>
            ) : (
              ""
            )}
          </li>
        ))}
      </ul>
    </SectionFilter>
  );
};

const SectionFilter = styled.div`
  padding: 10px 0px 10px 20px;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 560px) {
    flex-direction: column;
    align-items: flex-start;
  }

  & > p {
    color: rgb(48, 48, 48);
    font-weight: 800;
  }

  & > ul {
    list-style: none;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    & > li {
      color: var(--dark-green);
      margin: 5px 0px 5px 5px;
      font-size: 14px;
      font-weight: 700;
      display: flex;
      align-items: center;
      cursor: pointer;
      border-radius: 8px;
      padding: 2px 5px 2px 5px;
      border: 1px solid var(--dark-green);

      & > div {
        color: var(--dark-green);
      }

      & > i {
        padding-left: 15px;
      }

      &.filter-supplier-selected {
        background-color: var(--dark-green);
        border-radius: 8px;
        padding: 2px 5px 2px 5px;

        & > div {
          color: white;
        }
      }
    }
  }
`;

export default Filter;
