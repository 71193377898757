const getNextMonday = () => {
  var nextMonday = new Date();
  nextMonday.setDate(
    nextMonday.getDate() + ((((7 - nextMonday.getDay()) % 7) + 1) % 7)
  );
  const shortDate = nextMonday.toLocaleDateString("en-US", {
    month: "short",
    day: "2-digit"
  });
  return shortDate;
};

const getLastMonday = () => {
  var prevMonday = new Date();
  prevMonday.setDate(prevMonday.getDate() - ((prevMonday.getDay() + 6) % 7));
  const shortDate = prevMonday.toLocaleDateString("en-US", {
    month: "short",
    day: "2-digit"
  });
  return shortDate;
};

export const getMondayDate = () => {
  const today = new Date();
  const currentDay = today.getDay();
  if (currentDay === 0 || currentDay === 1 || currentDay === 6) {
    return getNextMonday();
  } else {
    return getLastMonday();
  }
};

export const getCurrentDayOfWeekName = () => {
  var d = new Date();
  var weekday = new Array(7);
  weekday[0] = "Sunday";
  weekday[1] = "Monday";
  weekday[2] = "Tuesday";
  weekday[3] = "Wednesday";
  weekday[4] = "Thursday";
  weekday[5] = "Friday";
  weekday[6] = "Saturday";

  return weekday[d.getDay()];
};

export const isFirstTimeAfterSecondTime = (date1, date2) => {
  const hours1 = date1.getHours();
  const minutes1 = date1.getMinutes();
  const hours2 = date2.getHours();
  const minutes2 = date2.getMinutes();

  return hours1 * 60 + minutes1 > hours2 * 60 + minutes2;
};
