import React from "react";
import PublicPageLayout from "../pageFragments/pageLayouts/PublicPageLayout";
import Section from "../pageFragments/Section";
import OfficeStatus from "../pageFragments/OfficeStatus";
import FilterableContentSection from "../pageFragments/content/FilterableContentSection";

const Home = () => {
  return (
    <PublicPageLayout>
      <OfficeStatus />
      <Section title={"LATEST FROM BHA"}>
        <FilterableContentSection />
      </Section>
    </PublicPageLayout>
  );
};

export default Home;
