import React, { useRef } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import styled from "styled-components";
import { CSSTransition } from "react-transition-group";

// import {
//   COLORS,
//   CONSTANTS,
//   FONTSIZE,
//   FONTWEIGHT
// } from "../../helpers/designConstants";
import CancelOutlined from "@material-ui/icons/CancelOutlined";

const Modal = ({
  show,
  closeButtonHidden,
  disableCloseOnOutsideClick,
  onClose,
  title,
  height,
  width,
  maxWidth,
  minWidth,
  contentPadding,
  children,
  className
}) => {
  const nodeRef = useRef();

  return ReactDOM.createPortal(
    <CSSTransition
      nodeRef={nodeRef}
      in={show}
      unmountOnExit
      timeout={{ enter: 0, exit: 300 }}
    >
      <StyledModal
        ref={nodeRef}
        className={className}
        onClick={disableCloseOnOutsideClick ? null : onClose}
      >
        <ModalContent
          onClick={(e) => e.stopPropagation()}
          height={height}
          width={width}
          maxWidth={maxWidth}
          minWidth={minWidth}
        >
          {closeButtonHidden ? (
            ""
          ) : (
            <CloseButton onClick={onClose}>
              <CancelOutlined style={{ fontSize: 30, fill: "black" }} />
            </CloseButton>
          )}
          <Container>
            {title !== undefined ? <Title>{title}</Title> : ""}
            <Content>{children}</Content>
          </Container>
        </ModalContent>
      </StyledModal>
    </CSSTransition>,
    document.getElementById("root")
  );
};

Modal.propTypes = {
  show: PropTypes.bool
};

const StyledModal = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  pointer-events: none;

  &.enter-done {
    opacity: 1;
    pointer-events: visible;
  }

  &.exit {
    opacity: 0;
  }
`;

const ModalContent = styled.div`
  @media screen and (max-width: 550px) {
    position: fixed;
    top: 10%;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateY(100vh);
  }

  @media screen and (min-width: 550px) {
    height: ${(props) => (props.height ? props.height : undefined)};
    width: ${(props) => (props.width ? props.width : undefined)};
    max-width: ${(props) => (props.maxWidth ? props.maxWidth : undefined)};
    min-width: ${(props) => (props.minWidth ? props.minWidth : undefined)};
    margin: 10px;
    margin-top: 60px;
    transform: translateY(-200px);
  }
  background-color: white;
  border-radius: 8px;
  position: relative;
  transition: all 0.3s ease-in-out;
  overflow: hidden;

  ${StyledModal}.enter-done & {
    transform: translateY(0);
  }

  ${StyledModal}.exit & {
    @media screen and (max-width: 550px) {
      transform: translateY(100vh);
    }

    @media screen and (min-width: 550px) {
      transform: translateY(-200px);
    }
  }
`;

const Container = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr;
  justify-items: center;
  width: 100%;
  overflow: auto;
`;

const Content = styled.div`
  padding: 20px;
  width: 100%;
`;

const Title = styled.div`
  padding: 10px;
  text-align: center;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 5px;
  right: 7px;
  width: 30px;
  height: 30px;
  color: black;
  cursor: pointer;
`;

export default Modal;
