import React from "react";
import styled from "styled-components";

const SupplierContentItem = ({ id, content, onEdit, onDelete }) => {
  return (
    <ContentOuterWrapper>
      <ContentInnerWrapper>
        <ImageWrapper>
          <img src={content.imageURL} alt='' />
        </ImageWrapper>
        <DetailsWrapper>
          <p>{content.name}</p>
          <p>{content.type}</p>
          <ContentUploadDate>
            Uploaded:
            {` ${new Date(content.uploadDate).toLocaleDateString(["en-US"], {
              month: "short",
              day: "numeric",
              year: "numeric"
            })}`}
          </ContentUploadDate>
        </DetailsWrapper>
      </ContentInnerWrapper>
      <EditButtonWrapper>
        <EditButton onClick={() => onEdit(id)}>Edit</EditButton>
        <DeleteButton onClick={() => onDelete(id)}>Delete</DeleteButton>
      </EditButtonWrapper>
    </ContentOuterWrapper>
  );
};

const ContentOuterWrapper = styled.div`
  margin: 0 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid black;
`;

const ContentInnerWrapper = styled.div`
  width: 100%;
  padding: 10px 0;
  display: flex;

  @media screen and (max-width: 550px) {
    flex-direction: column;
  }
`;

const ImageWrapper = styled.div`
  width: 200px;
  img {
    max-height: 100px;
  }
`;

const DetailsWrapper = styled.div`
  margin-left: 20px;
  p {
    color: black;
  }

  @media screen and (max-width: 550px) {
    margin-left: 0px;
  }
`;

const ContentUploadDate = styled.p`
  font-size: 9px;
  font-weight: 100;
`;

const EditButtonWrapper = styled.div`
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const EditButton = styled.button`
  border: none;
  border-radius: 8px;
  padding: 3px 10px;
  margin: 4px;
  font-family: "Montserrat", sans-serif;
  font-size: 1.1em;
  color: white;
  background-color: black;
  cursor: pointer;
`;

const DeleteButton = styled.button`
  border: none;
  border-radius: 8px;
  padding: 3px 10px;
  margin: 4px;
  font-family: "Montserrat", sans-serif;
  font-size: 1.1em;
  color: white;
  background-color: red;
  cursor: pointer;
`;

export default SupplierContentItem;
