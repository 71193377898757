import React, { Fragment } from "react";
import styled from "styled-components";
import { useLoading, Grid } from "@agney/react-loading";

const Loading = ({ isLoading }) => {
  const { containerProps, indicatorEl } = useLoading({
    loading: isLoading,
    indicator: <Grid width='100' />
  });

  return (
    <Fragment>
      {isLoading ? (
        <LoadingBackground>
          <LoadingIconContainer {...containerProps}>
            {indicatorEl}
          </LoadingIconContainer>
        </LoadingBackground>
      ) : null}
    </Fragment>
  );
};

export default Loading;

const LoadingBackground = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  z-index: 1000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadingIconContainer = styled.div`
  padding: 20px;
  border-radius: 15px;
  background-color: rgba(132, 132, 132, 0.8);
`;
