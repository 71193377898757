import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AuthContextProvider from "./context/useAuth";
import FirebaseContextProviderClient from "./context/useFirebaseClient";
import FirebaseContextProviderAdmin from "./context/useFirebaseAdmin";
import AdminRoute from "./routes/AdminRoute";
import AdminLogin from "./components/pages/AdminLogin";
import Home from "./components/pages/Home";
import Suppliers from "./components/pages/Suppliers";
import Contact from "./components/pages/Contact";
import OfficeStatusManager from "./components/pages/officeStatusAdmin/OfficeStatusManager";
import ManageContentAdmin from "./components/pages/manageContentAdmin/ManageContentAdmin";
import Footer from "./components/pageFragments/Footer";
import styled from "styled-components";

function App() {
  return (
    <div className='App'>
      <Router>
        <PageContainer>
          <PageContent>
            <Switch>
              <Route exact path={["/", "/suppliers", "/contact"]}>
                <FirebaseContextProviderClient>
                  <Route exact path='/' component={Home} />
                  <Route exact path='/suppliers' component={Suppliers} />
                  <Route exact path='/contact' component={Contact} />
                </FirebaseContextProviderClient>
              </Route>
              <Route exact path={["/adminLogin", "/admin", "/manage-content"]}>
                <AuthContextProvider>
                  <FirebaseContextProviderAdmin>
                    <Route exact path='/adminLogin'>
                      <AdminLogin />
                    </Route>
                    <AdminRoute exact path='/admin'>
                      <OfficeStatusManager />
                    </AdminRoute>
                    <AdminRoute exact path='/manage-content'>
                      <ManageContentAdmin />
                    </AdminRoute>
                  </FirebaseContextProviderAdmin>
                </AuthContextProvider>
              </Route>
            </Switch>
            <Footer />
          </PageContent>
        </PageContainer>
      </Router>
    </div>
  );
}

const PageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const PageContent = styled.div`
  width: 960px;
  background: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* display: grid;
  grid-template-rows: 1fr min-content; */

  @media only screen and (max-width: 959px) {
    width: 100%;
  }
`;

export default App;
