import React from "react";
import styled from "styled-components";

const ContentFragment = ({ itemDetails }) => {
  return (
    <ContentFragmentWrapper>
      <a href={itemDetails.url} target='_blank' rel='noopener noreferrer'>
        <ImageWrapper>
          <img src={itemDetails.imageURL} alt='none' />
        </ImageWrapper>

        <p>{itemDetails.supplier.toUpperCase()}</p>
        <p>{itemDetails.name.toUpperCase()}</p>
        <ContentUploadDate>
          Uploaded:
          {` ${new Date(itemDetails.uploadDate).toLocaleDateString(["en-US"], {
            month: "short",
            day: "numeric",
            year: "numeric"
          })}`}
        </ContentUploadDate>
      </a>
    </ContentFragmentWrapper>
  );
};

const ContentFragmentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 175px;
  text-align: center;
  margin: 20px 2px;

  & * {
    color: black;
    font-size: 12px;
    font-weight: 600;
  }

  @media only screen and (max-width: 560px) {
    width: 150px;
  }
`;

const ImageWrapper = styled.div`
  height: 150px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 10px;

  & img {
    max-width: 100%;
    max-height: 150px;
    width: auto;
    height: auto;
  }
`;

const ContentUploadDate = styled.p`
  font-size: 9px;
  font-weight: 100;
`;

export default ContentFragment;
