import React from "react";
import styled from "styled-components";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";

const Footer = () => {
  return (
    <FooterWrapper>
      <Content>
        <MainContent>
          BhA SHOWROOM
          <InfoContainer>
            <PhoneEmail>
              <p>p. 780-444-6396</p>
              <p>e. harrisgroup@bhainc.ca</p>
            </PhoneEmail>
            <Address>
              <p>17352 - 107 Ave</p>
              <p>Edmonton, AB T5S 1E9</p>
            </Address>
          </InfoContainer>
        </MainContent>
        <SocialMedia>
          <SocialMediaWrapper>
            <SocialMediaTitle>Follow us on Social Media</SocialMediaTitle>
            <SocialMediaIconsWrapper>
              <StyledFacebookIcon
                href='https://facebook.com/brianharrisagenciesinc'
                target='_blank'
              >
                <FacebookIcon fontSize={"large"} />
              </StyledFacebookIcon>
              <StyledInstagramIcon
                href='https://www.instagram.com/brianharrisagencies/'
                target='_blank'
              >
                <InstagramIcon fontSize={"large"} />
              </StyledInstagramIcon>
            </SocialMediaIconsWrapper>
          </SocialMediaWrapper>
        </SocialMedia>
      </Content>
      <Copyright>© 2021 Brian Harris Agencies Inc</Copyright>
    </FooterWrapper>
  );
};

const FooterWrapper = styled.div`
  /* height: 150px; */
  width: 100%;
  background: rgb(48, 48, 48);
  margin-top: auto;
  padding-bottom: 10px;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 550px) {
    flex-direction: column;
  }
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: 800;
  padding: 10px 0 0 20px;

  @media screen and (max-width: 550px) {
    align-items: center;
    padding: 10px 0 0 0;
  }
`;

const InfoContainer = styled.div`
  font-weight: 300;
  font-size: 14px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;

  @media screen and (max-width: 550px) {
    align-items: center;
    text-align: center;
  }
`;

const Address = styled.div``;

const PhoneEmail = styled.div`
  padding: 7px 0;
`;

const SocialMedia = styled.div`
  padding: 10px 20px 0 0;
  height: 100%;
  display: flex;
  align-items: center;

  @media screen and (max-width: 550px) {
    padding: 20px 0 20px 0;
  }
`;

const SocialMediaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media screen and (max-width: 550px) {
    align-items: center;
  }
`;

const SocialMediaTitle = styled.div``;

const SocialMediaIconsWrapper = styled.div``;

const StyledFacebookIcon = styled.a`
  margin: 10px;
  cursor: pointer;

  &:hover {
    color: var(--light-green);
  }
`;

const StyledInstagramIcon = styled.a`
  margin: 10px;
  cursor: pointer;

  &:hover {
    color: var(--light-green);
  }
`;

const Copyright = styled.div`
  text-align: center;
  font-weight: 300;
  font-size: 10px;
`;

export default Footer;
